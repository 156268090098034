import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import DetailedView from "../components/DetailedView";
import { getFormattedDate } from "../helper/commonHelper";
import { getTransactionalHotel } from "../services/apiService";

const TransactionalHotelDetails = (props) => {
  const [transactionalHotels, setTransactionalHotels] = useState({});
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [isDetailedView, setIsDetailedView] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [basicFirst, setBasicFirst] = useState(20);
  const [basicRows, setBasicRows] = useState(10);
  const [detailedViewSelectedItem, setDetailedViewSelectedItem] =
    useState(null);
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { hotelID } = useParams();

  const { hotelList = [] } = props;
  const [filterDate, setFilterDate] = useState({
    date_start: getFormattedDate(1),
    date_end: getFormattedDate(),
    service_type: "",
  });
  useEffect(() => {
    fetchTransactionalHotels();
  }, [basicFirst, basicRows]);

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setGlobalFilterValue1(value);
  };

  const dateClickHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilterDate({ ...filterDate, [name]: value });
  };

  const fetchTransactionalHotels = async () => {
    try {
      setIsLoading(true);
      const response = await getTransactionalHotel(
        filterDate.service_type,
        hotelID,
        pageNo,
        basicRows,
        filterDate.date_start,
        filterDate.date_end
      );
      if (response.status === 200) {
        const data = response.data.data || {};
        setTransactionalHotels(data.orders);
        setRevenue(data.total_revenue);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const detailViewClickHandler = (item) => {
    setIsDetailedView(true);
    setDetailedViewSelectedItem(item);
  };
  const applyFilters = () => {
    fetchTransactionalHotels();
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>Revenue: ₹ {revenue}</div>
        <input
          type="search"
          style={{ maxWidth: "20rem" }}
          value={globalFilterValue1}
          onChange={(e) => onGlobalFilterChange1(e)}
          placeholder="Search by Hotel/Restaurant Name"
          className="form-control"
        />
      </div>
    );
  };
  const onBasicPageChange = (event) => {
    setPageNo(event.page + 1);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  const renderSystemActions = (item) => {
    return (
      <div style={{ display: "flex" }}>
        <button
          className="btn btn-icon-primary btn-icon"
          onClick={() => detailViewClickHandler(item)}
        >
          <i className="pi pi-eye"></i>
        </button>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      <div className="dates-filter-wrapper">
        <div className="form-group">
          <label className="label">Start Date</label>
          <input
            type="date"
            name="date_start"
            className="form-control"
            value={filterDate.date_start}
            onChange={(e) => dateClickHandler(e)}
          />
        </div>
        <div className="form-group">
          <label className="label">End Date</label>
          <input
            type="date"
            name="date_end"
            className="form-control"
            value={filterDate.date_end}
            onChange={(e) => dateClickHandler(e)}
          />
        </div>
        {/* <div className="form-group">
          <label className="label">Service Type</label>
          <select
            id="service_type"
            name="service_type"
            className="form-control"
            onChange={(e) => dateClickHandler(e)}
            value={filterDate.service_type}
          >
            <option value="" defaultChecked>
              --select option--
            </option>
            <option value="ird">IRD</option>
            <option value="ird_restaurant">Restaurant</option>
          </select>
        </div> */}
        <div className="form-group">
          {/* <label className="label">Hotel List </label>
          <select
            id="hotel_id"
            name="hotel_id"
            className="form-control"
            onChange={(e) => dateClickHandler(e)}
            value={filterDate.hotel_id}
          >
            <option value="" defaultChecked>
              --select option--
            </option>
            {hotelList.map((item, index) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select> */}
          {/* <input
            list="browsers"
            name="hotel_id"
            id="hotel_id"
            className="form-control"
            onChange={(e) => dateClickHandler(e)}
            value={filterDate.hotel_id}
          />
          <datalist id="browsers">
            <option value="" defaultChecked>
              --select option--
            </option>
            {hotelList.map((item, index) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </datalist> */}
        </div>
        <div className="form-group">
          <button className="btn btn-primary" onClick={applyFilters}>
            Apply Filter
          </button>
        </div>
      </div>
      <DataTable
        value={transactionalHotels.data || []}
        className="systems-table"
        showGridlines
        paginator
        dataKey="id"
        rows={20}
        filters={filters1}
        filterDisplay="menu"
        responsiveLayout="scroll"
        globalFilterFields={["hotel_name", "service_type"]}
        header={header}
        loading={isLoading}
        emptyMessage="No Hotels Found"
      >
        <Column
          field={({ hotel_name }) => hotel_name}
          header="Hotel Name"
        ></Column>

        <Column
          field={({ service_type }) => service_type.toUpperCase()}
          header="Service Type"
        ></Column>
        <Column
          field={({ ordered_at }) => new Date(ordered_at).toDateString()}
          header="Order Date"
        ></Column>
        <Column
          field={({ status }) => status.toUpperCase()}
          header="Order Status"
        ></Column>
        <Column
          field={({ revenue }) => `₹ ${revenue}`}
          header="Revenue"
        ></Column>
        <Column
          field={(data) => renderSystemActions(data)}
          header="Actions"
        ></Column>
      </DataTable>
      {/* 
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={transactionalHotels.total}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        onPageChange={onBasicPageChange}
      ></Paginator> */}
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={transactionalHotels.total}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onBasicPageChange}
      ></Paginator>

      {isDetailedView && detailedViewSelectedItem && (
        <DetailedView
          itemData={detailedViewSelectedItem}
          isOpen={isDetailedView}
          closeHandler={() => {
            setIsDetailedView(false);
            setDetailedViewSelectedItem(null);
          }}
        />
      )}
    </>
  );
};

export default React.memo(TransactionalHotelDetails);
