import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { login } from "../services/apiService";
import { setCookie } from "../helper/cookieHelper";

const Login = () => {
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    device_token: "abcdef",
    device_type: "web",
  });
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();
  const showToast = (type, summary, details) => {
    toast.current.show({
      severity: type || "success",
      summary: summary || "Success Message",
      detail: details || "",
      life: 50000,
    });
  };
  const handleLoginClick = async () => {
    if (!loginData.username || !loginData.username.trim()) {
      showToast("error", "Username is required");
      return;
    }
    if (!loginData.password || !loginData.password.trim()) {
      showToast("error", "Password is required");
      return;
    }
    try {
      setLoading(true);
      const response = await login(JSON.stringify(loginData));
      if (response.status === 200) {
        const data = response.data || {};
        setCookie("_token", `${data.access_token}`, 7);
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.log(error);
      console.error(error);
      showToast("error", "Something went wrong!! please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };
  return (
    <div className="login-page">
      <div className="login-wrapper">
        <div className="app-logo " />
        <div className="login-box">
          <div className="login-title text-center">
            <div>Welcome back,</div>
            <span>Please sign to access to your Product Reports</span>
          </div>
          <input
            type="text"
            name="username"
            className="form-control"
            placeholder="Enter username"
            value={loginData.username}
            onChange={(e) => handleInputChange(e)}
          />
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Enter Password"
            value={loginData.password}
            onChange={(e) => handleInputChange(e)}
          />
          {/* <Input type="checkbox" name="check" id="exampleCheck" />
          <Label for="exampleCheck" check>
            Keep me logged in
          </Label> */}
          {/* <h6 className="mb-0">
            No account?{" "}
            <a
              href="https://colorlib.com/"
              onClick={(e) => e.preventDefault()}
              className="text-primary"
            >
              Sign up now
            </a>
          </h6> */}
          {/* <div className="float-left">
                <a
                  href="https://colorlib.com/"
                  onClick={(e) => e.preventDefault()}
                  className="btn-lg btn btn-link"
                >
                  Recover Password
                </a>
              </div> */}
          <button
            className="btn btn-primary"
            onClick={handleLoginClick}
            disabled={loading}
          >
            {loading && <i className="pi pi-spin pi-spinner"></i>} Login to
            Dashboard
          </button>
        </div>
        <div
          className="text-center text-white opacity-8 mt-3"
          style={{ textAlign: "center" }}
        >
          Copyright &copy; ViralOps 2022
        </div>
      </div>
      <Toast ref={toast}></Toast>
    </div>
  );
};

export default Login;
