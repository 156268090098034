import axios from "axios";
import { getCookie } from "../helper/cookieHelper";
import { API_URL } from "../constants/typeCodes";

//  axio header setup
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";
axios.defaults.headers.post["Content-Type"] = "application/json";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const token = getCookie("_token");
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// start auth apis
export const login = (data) => {
  return axios.post("login", data);
};
export const logout = (data) => {
  return axios.get("logout");
};

// end auth apis
export const getHotelList = () => {
  return axios.get("supervisor/hotels");
};
export const getRevenue = (base_url, date_start, date_end) => {
  const yearStart = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
    date_start
  );
  const monthStart = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
    date_start
  );
  const yearEnd = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
    date_end
  );
  const monthEnd = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
    date_end
  );

  return axios.get(
    `supervisor/hotels/${base_url}?&year_start=${yearStart}&year_end=${yearEnd}&month_start=${monthStart}&month_end=${monthEnd}`
  );
};
export const getTransactionalHotel = (
  service_type,
  hotel_id,
  page,
  totalCount,
  date_start,
  date_end
) => {
  return axios.get(
    `supervisor/hotels/transactional/orders?service_type=${service_type}&hotel_id=${hotel_id}&page=${page}&per_page=${totalCount}&date_start=${date_start}&date_end=${date_end}`
  );
};
export const getViewOnlyHotel = (date_start, date_end) => {
  return axios.get(`supervisor/hotels/view-only`);
};
