import React, { useState } from "react";
import { useNavigate } from "react-router";
import { setCookie } from "../helper/cookieHelper";
import { logout } from "../services/apiService";
import { ProgressSpinner } from "primereact/progressspinner";

const Header = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { totalRevenue } = props;
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let response = await logout();
      if (response.status === 200) {
        setCookie("_token", ``, -1);
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <nav className="top-nav">
      {/* <div className="revenue-count">Total Revenue : ${totalRevenue}</div> */}
      <ul>
        <li>
          <a href="#" onClick={(e) => handleLogout(e)}>
            {isLoading && (
              <i
                className="pi pi-spin pi-spinner"
                style={{ marginRight: "10px" }}
              ></i>
            )}
            <i className="pi pi-sign-out"></i>
            LogOut
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
