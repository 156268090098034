import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { useState, useEffect } from "react";
import DetailedView from "../components/DetailedView";
import {
  getFormattedDate,
  getTotalRevenueUSD,
  getYearBackTimeStamp,
} from "../helper/commonHelper";
import { getRevenue } from "../services/apiService";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import moment from "moment";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const getDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 3);
  return date;
};
const TransactionalHotels = (props) => {
  const [transactionalHotels, setTransactionalHotels] = useState({});
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [isDetailedView, setIsDetailedView] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalHotels, setTotalHotels] = useState({});
  const [detailedViewSelectedItem, setDetailedViewSelectedItem] =
    useState(null);
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const navigate = useNavigate();

  const [date_start, setDate_start] = useState(getDate());
  const [date_end, setDate_end] = useState(new Date());
  useEffect(() => {
    applyFilters();
  }, []);

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setGlobalFilterValue1(value);
  };

  const dateClickHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
  };

  const fetchRevenue = async () => {
    try {
      setIsLoading(true);
      const response = await getRevenue("chain-revenues", date_start, date_end);
      if (response.status === 200) {
        const data = response.data.data || {};
        if (data.revenues?.length > 0) {
          let res = getHotelOutletsGrouped(data.revenues);
          setTotalHotels(res);
        }
        setTransactionalHotels(data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getHotelOutletsGrouped = (data) => {
    let result = data.reduce((acc, item) => {
      if (!acc[item.service_type]) {
        acc[item.service_type] = 1;
      } else {
        acc[item.service_type] += 1;
      }
      return acc;
    }, {});
    return result;
  };

  const detailViewClickHandler = (item) => {
    // setIsDetailedView(true);
    // setDetailedViewSelectedItem(item);
    navigate(`/hotel/${item.id}`);
  };
  const applyFilters = () => {
    fetchRevenue();
  };
  const renderItemsPerOrder = ({ order_counts = {}, item_counts = {} }) => {
    let average = 0;
    if (
      item_counts.ird_and_restaurant === 0 ||
      isNaN(item_counts.ird_and_restaurant)
    ) {
      return average.toFixed(2);
    } else {
      return (
        item_counts.ird_and_restaurant / order_counts.ird_and_restaurant
      ).toFixed(2);
    }
  };
  const revenuePerOrder = ({ total_revenue, order_counts = {} }) => {
    let average = 0;
    if (total_revenue === 0 || isNaN(total_revenue)) {
      average = average;
    } else {
      average = total_revenue / order_counts.ird_and_restaurant;
    }
    return average.toFixed(2);
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="data-item">
          Date Range:{" "}
          <span style={{ color: "red" }}>
            {moment(date_start).format("YYYY-MM")} to{" "}
            {moment(date_end).format("YYYY-MM")}
          </span>{" "}
          Live Hotels:
          <span>{transactionalHotels.revenues?.length}</span>
          Live Outlets:
          <span>
            {transactionalHotels.hotel_count?.chain_hotels +
              transactionalHotels.hotel_count?.hotel}
          </span>{" "}
          Transactional Outlets:
          <span>
            {transactionalHotels.hotel_count?.transactional || 0}
          </span>{" "}
          View-Only Outlets:
          <span>{transactionalHotels.hotel_count?.view_only}</span>
          <div>
            Total Revenue:
            <span>
              ${getTotalRevenueUSD(transactionalHotels.revenues || [])}
            </span>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <input
            type="search"
            style={{ maxWidth: "15rem", marginRight: "10px" }}
            value={globalFilterValue1}
            onChange={(e) => onGlobalFilterChange1(e)}
            placeholder="Search by Property Name"
            className="form-control"
          />
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-primary"
            table="table-ird"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download"
          />
        </div>
      </div>
    );
  };

  const renderServiceType = (service_type) => {
    let types = {
      ird_and_restaurant: "IRD and F&B Outlets",
      ird: "IRD",
      ird_restaurant: "F&B Outlets",
    };
    return types[service_type] || service_type;
  };

  const header = renderHeader();
  return (
    <div className="main-wrapper-view">
      <DataTable
        value={transactionalHotels.revenues || []}
        className="systems-table"
        showGridlines
        paginator
        dataKey="id"
        filters={filters1}
        rows={20}
        filterDisplay="menu"
        responsiveLayout="scroll"
        globalFilterFields={["name", "restaurant_name"]}
        header={header}
        loading={isLoading}
        emptyMessage="No Hotels Found"
      >
        <Column field={(item) => item.name} header="Property Name"></Column>
        <Column field={(item) => item.country.name} header="Country"></Column>
        <Column
          field={({ hotel_count = {} }) =>
            hotel_count.transactional != 0 && hotel_count.view_only != 0
              ? "Transactional & View Only"
              : hotel_count.view_only == 0 && hotel_count.transactional > 0
              ? "Transactional"
              : hotel_count.transactional == 0 && hotel_count.view_only > 0
              ? "View Only"
              : hotel_count.transactional == 0 && hotel_count.view_only == 0
              ? "View Only"
              : "Transactional & View Only"
          }
          header="Menu Type (Transactional/View Only)"
        ></Column>
        <Column
          field={(item) =>
            `${months[new Date(item.created_at).getMonth()]} ${new Date(
              item.created_at
            ).getFullYear()}`
          }
          header="Go Live Month"
        ></Column>
        <Column
          field={(item) => `$ ${item.total_revenue || 0}`}
          header={() =>
            `Total Revenue: $${getTotalRevenueUSD(
              transactionalHotels.revenues || []
            )}`
          }
        ></Column>

        <Column
          field={({ order_counts = {} }) => order_counts.ird_and_restaurant}
          header=" No. of Orders"
        ></Column>

        <Column
          field={(item) => "$" + revenuePerOrder(item)}
          header="Revenue Per Order"
        ></Column>

        {/* <Column
          field={({ item_counts = {} }) => item_counts.ird_and_restaurant}
          header="Total Items"
        ></Column> */}
        <Column
          field={(item) => renderItemsPerOrder(item)}
          header="Items per Order"
        ></Column>

        <Column
          field={({ hotel_count = {} }) =>
            hotel_count.chain_hotels + hotel_count.hotel
          }
          header="Live Outlets"
        ></Column>
        <Column
          field={({ hotel_count = {} }) => hotel_count.transactional}
          header="Transactional Outlet"
        ></Column>
        <Column
          field={({ hotel_count = {} }) => hotel_count.view_only}
          header="View Only Outlet"
        ></Column>
      </DataTable>
      <div className="date-filter-wrap">
        <div className="dates-filter-wrapper">
          <div className="form-group">
            <label className="label">Start Month-Year</label>
            <DatePicker
              selected={date_start}
              onChange={(date) => setDate_start(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label className="label">End Month-Year</label>
            <DatePicker
              selected={date_end}
              onChange={(date) => setDate_end(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary" onClick={applyFilters}>
              Apply Filter
            </button>
          </div>
        </div>

        {/* <div className="total">
          Total Revenue : $
          {getTotalRevenueUSD(transactionalHotels.revenues || [])}
        </div> */}

        {/* <div className="form-group">
          <label className="label">Hotel List </label>
          <select
            id="hotel_id"
            name="hotel_id"
            className="form-control"
            onChange={(e) => dateClickHandler(e)}
            value={filterDate.hotel_id}
          >
            <option value="" defaultChecked>
              --select option--
            </option>
            {hotelList.map((item, index) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <input
            list="browsers"
            name="hotel_id"
            id="hotel_id"
            className="form-control"
            onChange={(e) => dateClickHandler(e)}
            value={filterDate.hotel_id}
          />
          <datalist id="browsers">
            <option value="" defaultChecked>
              --select option--
            </option>
            {hotelList.map((item, index) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </datalist>
        </div> */}
      </div>
      <table id="table-ird" style={{ display: "none" }}>
        <thead>
          <tr>
            <th>Property Name</th>
            <th>Country</th>
            <th>Menu Type (Transactional/View Only)</th>

            <th>Go Live Month</th>
            <th>
              Total Revenue: $
              {getTotalRevenueUSD(transactionalHotels.revenues || [])}
            </th>
            <th>No. of Orders</th>
            <th>Revenue Per Order"</th>
            <th>Items per Order</th>
            <th>Live Outlets</th>
            <th>Transactional Outlet</th>
            <th>View Only Outlet</th>
          </tr>
        </thead>
        <tbody>
          {transactionalHotels.revenues?.map((item, index) => {
            return (
              <tr key={item.id}>
                <th>{item.name}</th>
                <th>{item.country.name}</th>
                <th>
                  {item?.hotel_count?.transactional != 0 &&
                  item?.hotel_count?.view_only != 0
                    ? "Transactional & View Only"
                    : item?.hotel_count?.view_only == 0 &&
                      item?.hotel_count?.transactional > 0
                    ? "Transactional"
                    : item?.hotel_count?.transactional == 0 &&
                      item?.hotel_count?.view_only > 0
                    ? "View Only"
                    : item?.hotel_count?.transactional == 0 &&
                      item?.hotel_count?.view_only == 0
                    ? "View Only"
                    : "Transactional & View Only"}
                </th>
                <th>
                  {months[new Date(item.created_at).getMonth()]}
                  {new Date(item.created_at).getFullYear()}
                </th>
                <th>${item.total_revenue}</th>
                <th>{item?.order_counts?.ird_and_restaurant}</th>
                <th>$ {revenuePerOrder(item)}</th>

                <th>{renderItemsPerOrder(item)}</th>
                <th>
                  {item?.hotel_count?.chain_hotels + item?.hotel_count?.hotel}
                </th>
                <th>{item?.hotel_count?.transactional}</th>
                <th>{item?.hotel_count?.view_only}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isDetailedView && detailedViewSelectedItem && (
        <DetailedView
          itemData={detailedViewSelectedItem}
          isOpen={isDetailedView}
          closeHandler={() => {
            setIsDetailedView(false);
            setDetailedViewSelectedItem(null);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(TransactionalHotels);
