import React, { useState, useEffect } from "react";
import { getRevenue } from "../services/apiService";
import { TabView, TabPanel } from "primereact/tabview";
import { getFormattedDate, getTotalRevenueUSD } from "../helper/commonHelper";
import Header from "../components/Header";
import ChartPage from "./ChartPage";
import ViewOnlyHotels from "./ViewOnlyHotels";
import TransactionalHotels from "./TransactionalHotels";
import DeliveryRevenue from "./DeliveryRevenue";

const Dashboard = () => {
  const [revenue, setRevenue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [filterDate, setFilterDate] = useState({
    date_start: getFormattedDate(1),
    date_end: getFormattedDate(),
  });

  useEffect(() => {
    applyFilters();
  }, []);

  const fetchRevenue = async () => {
    try {
      setIsLoading(true);
      const response = await getRevenue(
        "",
        filterDate.date_start,
        filterDate.date_end
      );
      if (response.status === 200) {
        const data = response.data.data || {};
        setRevenue(data.revenues || []);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const dateClickHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilterDate({ ...filterDate, [name]: value });
  };

  const applyFilters = () => {
    fetchRevenue();
  };
  const getSlectedTopHotels = (re) => {
    let filteredData = JSON.parse(JSON.stringify(re));
    if (filteredData.length > 10) {
      filteredData = filteredData.splice(0, 10);
    }
    return filteredData;
  };

  return (
    <>
      <Header totalRevenue={0} />
      <div className="dashboard-main">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          {/* <TabPanel header="Chart View">
            <>
              <div className="date-filter-wrap">
                <div className="dates-filter-wrapper">
                  <div className="form-group">
                    <label className="label">Start Date</label>
                    <input
                      type="month"
                      name="date_start"
                      className="form-control"
                      value={filterDate.date_start}
                      // min={getYearBackTimeStamp(filterDate.date_end, 1)}
                      // max={getYearBackTimeStamp(filterDate.date_end)}
                      onChange={(e) => dateClickHandler(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="label">End Date</label>
                    <input
                      type="month"
                      name="date_end"
                      className="form-control"
                      // min={getYearBackTimeStamp(filterDate.date_start)}
                      // max={getFormattedDate()}
                      value={filterDate.date_end}
                      onChange={(e) => dateClickHandler(e)}
                    />
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary" onClick={applyFilters}>
                      Apply Filter
                    </button>
                  </div>
                </div>
                <div className="total">
                  Total Revenue : ${getTotalRevenueUSD(revenue || [])}
                </div>
              </div>
              <ChartPage
                chartData={getSlectedTopHotels(revenue)}
                isLoading={isLoading}
              />
            </>
          </TabPanel> */}
          <TabPanel header="Vserve Protecht">
            <TransactionalHotels />
          </TabPanel>
          <TabPanel header="Vserve Delivery">
            <DeliveryRevenue />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default Dashboard;
