export const getFormattedDate = (monthBack) => {
  let d = new Date();
  if (monthBack) {
    let currentMnth = d.getMonth();
    if (currentMnth > monthBack) d.setMonth(currentMnth - monthBack);
  }
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${year}-${month}`;
};

export const getTotalRevenueUSD = (dataItems = [], type) => {
  if (!dataItems.length) return 0;
  let total = 0;
  debugger;
  total = dataItems.reduce((acc, item) => {
    // let rate = item.country.usd_rate || 1;
    // let revenue = item.revenue / rate;
    let total = 0;
    if (type === "delivery") {
      total = item.revenue || 0;
    } else {
      total = (item.revenue || 0) + (item.restaurant_revenue || 0);
    }
    return acc + total;
  }, 0);

  return total.toFixed(2);
};

export const getYearBackTimeStamp = (datetime, gobackyear) => {
  let d = new Date(datetime);
  if (gobackyear) {
    d.setFullYear(d.getFullYear() - 1);
  }
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${year}-${month}`;
};
