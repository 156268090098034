import React from "react";
import { Dialog } from "primereact/dialog";

const DetailedView = (props) => {
  const { isOpen, OpenHandler, closeHandler, itemData } = props;
  console.log({ itemData });
  return (
    <Dialog
      header="Order Detail"
      visible={isOpen}
      style={{ width: "60vw" }}
      onHide={closeHandler}
    >
      <div className="details-container">
        <div className="details-col">
          <div className="label">Order ID</div>
          <div className="value">{itemData.id}</div>
        </div>
        <div className="details-col">
          <div className="label">Room No.</div>
          <div className="value">{itemData.premise}</div>
        </div>
        <div className="details-col">
          <div className="label">No of guest</div>
          <div className="value">{itemData.no_of_guest}</div>
        </div>

        <div className="details-col">
          <div className="label">Hotel Name</div>
          <div className="value">{itemData.hotel_name}</div>
        </div>

        <div className="details-col">
          <div className="label">Revenue</div>
          <div className="value">₹ {itemData.revenue}</div>
        </div>
        <div className="details-col">
          <div className="label">Service type</div>
          <div className="value">{itemData.service_type}</div>
        </div>
        <div className="details-col">
          <div className="label">Order status</div>
          <div className="value">{itemData.status}</div>
        </div>
        <div className="details-col">
          <div className="label">Tax Type</div>
          <div className="value">{itemData.taxation_type}</div>
        </div>
        <div className="details-col">
          <div className="label">Is Complementary</div>
          <div className="value">
            {itemData.is_complementary ? "Yes" : "No"}
          </div>
        </div>
        <div className="details-col">
          <div className="label">Order Date</div>
          <div className="value">
            {new Date(itemData.ordered_at).toDateString()}
          </div>
        </div>
        <div className="details-col">
          <div className="label">Order Items</div>
          <div className="value">
            <ul>
              {itemData.order_details?.map((item) => (
                <li>
                  {item.item_name} - {item.quantity}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default DetailedView;
